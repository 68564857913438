<template>
  <div>
    <div v-if="signedIn">
      <template v-if="loading">
        <loading />
      </template>
      <template v-else>
        <b-table :data="gCourses" hoverable>
          <b-table-column
            field="#"
            label="#"
            width="40"
            numeric
            v-slot="props"
            >{{ gCourses.indexOf(props.row) + 1 }}</b-table-column
          >

          <b-table-column label="School Class" v-slot="props">{{
            props.row.name
          }}</b-table-column>

          <b-table-column label="Subject" v-slot="props">{{
            props.row.section
          }}</b-table-column>

          <b-table-column label="Status" v-slot="props">{{
            props.row.course_state
          }}</b-table-column>

          <b-table-column label="Actions" width="160" v-slot="props">
            <action-buttons
              @initialize-info="initializeInfo(props.row)"
              @initialize-update="initializeUpdate(props.row)"
              @initialize-remove="initializeRemove(props.row)"
            />
          </b-table-column>
        </b-table>
      </template>
    </div>
    <div v-else>
      <p>
        You want to access your schools secured access point for classrooms. To
        begin you must authorize the application.
      </p>
      <button id="authorize" class="button is-success is-outlined mt-3">
        Authorize
      </button>
    </div>
    <!-- Delete -->
    <app-modal context="remove" @remove="deleteCourse" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
const axios = require('axios')
import { fetchAppUrl, fetchUser } from '../../assets/js/app_info'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      url: null,
      user: null,
      scope: null,
      schoolId: null,
      signedIn: false,
      token: '1qa2ws',
      pageTitle: 'G Suite Courses',
      callbackUrl: null,
      gCourses: [],
      schoolClass: null,
      loading: true,
      clientId: null,
    }
  },
  apollo: {
    schoolClass: {
      query: gql`
        query SchoolClassForGCourse($id: ID!) {
          schoolClass(id: $id) {
            id
            name
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
    },
  },
  methods: {
    initializeInfo(g_course) {
      this.gCourse = g_course
      this.$router.push(
        `/school/${this.schoolId}/g_courses/${this.schoolClass.id}/school_class/${g_course.name}/subject/${g_course.section}/g_course_info/${g_course.id}`
      )
    },
    initializeUpdate(g_course) {
      this.gCourse = g_course
      this.$router.push(`/school/${this.schoolId}/g_course/${g_course.id}`)
    },
    initializeRemove(g_course) {
      this.gCourse = g_course
      this.openModal()
    },
    init() {
      gapi.load('auth2', () => {
        gapi.auth2
          .init({
            client_id: this.clientId,
            ux_mode: 'redirect',
            redirect_uri: 'http://localhost:3000/e_courses_callback',
          })
          .then(
            (GoogleAuth) => {
              GoogleAuth.grantOfflineAccess({
                prompt: 'select_account',
                scope: this.scope,
              }).then((data) => {
                this.updateGoogleToken(data.code)
              })
            },
            (error) => {
              // console.log(error);
            }
          )
          .catch((error) => {
            // console.log(error);
          })
      })
    },
    updateGoogleToken(code) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateGoogleToken($id: Int!, $code: String!) {
              updateGoogleToken(input: { id: $id, code: $code }) {
                user {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.user.id,
            code: code,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateGoogleToken.errors,
            'Successfully authorized.'
          ).then(() => {
            // this.signedIn = true;
            this.$store.dispatch('logOut')
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
    fetch() {
      axios
        .get(`${this.url}/g_courses?user_id=${this.user.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          // console.log(error);
        })
    },
    deleteCourse() {
      fetch(`${this.url}/g_courses/${this.gCourse.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ user_id: this.user.id, course: this.gCourse }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            crudNotification([], data.success)
            this.closeModal()
            this.gCourses = this.gCourses.filter(
              (gCourse) => gCourse.id != this.gCourse.id
            )
          }
        })
        .catch((error) => {
          // console.log(error);
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchAppUrl().then((url) => {
      this.url = url
    })

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
      this.clientId = user.client_id

      if (user.role == 'admin') {
        this.$store.commit('setSubMenus', [
          {
            name: 'School Class Info',
            route: `/school/${this.schoolId}/school_class_info/${this.$route.params.id}`,
          },
        ])
      } else if (user.role == 'educator') {
        this.scope = `https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/classroom.topics https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.announcements`

        this.$store.commit('setSubMenus', [
          {
            name: 'School Class Info',
            route: `/school/${this.schoolId}/school_class_info/${this.$route.params.id}`,
          },
          {
            name: 'New Course',
            route: `/school/${this.schoolId}/new_g_course`,
          },
        ])
      }

      if (user.google_token && user.google_token.hasOwnProperty('scope')) {
        fetch(`${this.url}/g_courses?user_id=${this.user.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.message) {
              crudNotification([], data.message)
            } else {
              this.gCourses = data.filter(
                (gCourse) => gCourse.name == this.schoolClass.name
              )
              this.loading = false
            }
          })
          .catch((error) => {
            // console.log(error);
          })
        this.signedIn = true
      } else {
        if (user.role === 'admin') {
          this.$router.push(`/school/${this.schoolId}/admins`)
        } else {
          document.getElementById('authorize').addEventListener('click', () => {
            let externalScript = document.createElement('script')
            externalScript.setAttribute(
              'src',
              `https://apis.google.com/js/platform.js?onload=${this.init()}`
            )
            document.head.appendChild(externalScript)
          })
        }
      }
    })
  },
}
</script>
